import mailIcon from "../../assets/mailIcon.svg";
import mailIconDark from "../../assets/mailIconDark.svg";
import chatIcon from "../../assets/Chat.svg";
import chatIconDark from "../../assets/ChatDark.svg";
// import infoIcon from "../../assets/infoSquare.svg";

export const supportItems = [
  {
    to: "https://chat.whatsapp.com/Ikjuq4qZHtY8rCNPuELLsi",
    icon: <img src={chatIcon} alt="home Icon" className="dark:hidden" />,
    iconDark: (
      <img src={chatIconDark} alt="home Icon" className="dark:block hidden" />
    ),

    title: "Need Help?",
    subtitle: "Chat us on Whatsapp",
  },
  {
    to: "mailto:support@cwito.com",
    icon: <img src={mailIcon} alt="Campaign Icon" className="dark:hidden" />,
    iconDark: (
      <img
        src={mailIconDark}
        alt="Campaign Icon"
        className="dark:block hidden"
      />
    ),
    title: "Send Mail",
    subtitle: "Give us a feedback",
  },
];
