import React from "react";
import cancel from "../../assets/cancelIcon.svg";
import surveyIcon from "../../assets/surveyIcon.svg";
import arrowRightWhite from "../../assets/arrowRightWhite.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "configuration/AuthContext";

const SurveyModal = () => {
  const { setSurveyIsSubmitted } = useAuth();
  const navigate = useNavigate();
  const handleSurveyClick = () => {
    navigate("/survey");
  };

  const handleClick = () => {
    setSurveyIsSubmitted(true);
  };

  return (
    <div className="md:max-w-[70%] w-[90%] mx-auto  bg-[#FFFF8D] py-2.5 px-4 flex flex-col gap-2 rounded-2xl shadow-xl">
      <div className="w-full gap-3 p items-start justify-between flex">
        <img src={surveyIcon} alt="survey icon" />
        <p className="text-xs w-[70%] text-start text-[#023436]">
          Help us improve your experience! Share your thoughts with us on how
          you feel using our app by answering a few quick questions.
        </p>
        <button className="p-1" onClick={handleClick}>
          <img src={cancel} alt="close icon" />
        </button>
      </div>
      <div className="w-full flex justify-end">
        <button
          onClick={handleSurveyClick}
          className="bg-[#023436] py-1.5 px-3 rounded flex justify-center items-center"
        >
          <img src={arrowRightWhite} alt="arrowRightWhite" />
        </button>
      </div>
    </div>
  );
};

export default SurveyModal;
