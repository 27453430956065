import home from "../../assets/Home.svg";
import homeActive from "../../assets/HomeActive.svg";
import homeDark from "../../assets/HomeDark.svg";
import homeDark2 from "../../assets/HomeDark2.svg";
import walletIconActive from "../../assets/walletIcon.svg";
import walletIcon from "../../assets/walletIconActive.svg";
import walletIconDark from "../../assets/walletIconDark.svg";
import transactionIconActive from "../../assets/transactionActive.svg";
// import settingsIcon from "../../assets/settingsIcon.svg";
import settingsIconActive from "../../assets/settingsIconActive.svg";
import settingsIconDark from "../../assets/settingsDark.svg";
import settingsIcon from "../../assets/settingsDark2.svg";
import transactionIconDark from "../../assets/TtransactionDark2.svg";
import transactionIcon from "../../assets/transactionDarkIcon.svg";

export const sideBarItems = [
  {
    to: "/home",
    icon: <img src={homeActive} alt="home Icon" />,
    iconActive: <img src={homeDark} alt="home Icon" />,
    iconDark: <img src={homeDark2} alt="home Icon" />,
    title: "Home",
  },
  {
    to: "/naira-wallet",
    icon: <img src={walletIcon} alt="home Icon" />,
    iconActive: <img src={walletIconActive} alt="home Icon" />,
    iconDark: <img src={walletIconDark} alt="wallet icon" />,
    title: "Wallet",
  },
  {
    to: "/transactions",
    icon: <img src={transactionIconDark} alt="transaction Icon" />,
    iconActive: <img src={transactionIconActive} alt="transaction Icon" />,
    iconDark: <img src={transactionIcon} alt="transaction Icon" />,
    title: "Transactions",
  },
  {
    to: "/settings",
    icon: <img src={settingsIcon} alt="Settings Icon" />,
    iconActive: <img src={settingsIconActive} alt="Settings Icon" />,
    iconDark: <img src={settingsIconDark} alt="Settings Icon" />,
    title: "Settings",
  },
];
export const sideBarItems1 = [
  {
    to: "/home",
    icon: <img src={home} alt="home Icon" />,
    iconDark: <img src={homeDark} alt="home Icon" />,
    title: "Home",
  },

  {
    to: "/transactions",
    icon: <img src={transactionIcon} alt="transaction Icon" />,
    iconDark: <img src={transactionIconDark} alt="transaction Icon" />,
    title: "Transactions",
  },
  {
    to: "/settings",
    icon: <img src={settingsIcon} alt="Settings Icon" />,
    iconDark: <img src={settingsIconDark} alt="Settings Icon" />,
    title: "Settings",
  },
];
