import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { cwitoApi } from "./AxiosConfig";
import { UserData, UserDetails } from "types/SigInTypes";
import { useNavigate } from "react-router-dom";
import { NotificationsEntity } from "types/NotificationTypes";
// import { RefereeId } from "types/ReferralTypes";
import { RefereeId } from "types/ReferralTypes";
import { RatesEntity } from "types/RateTypes";
import { QuestionsEntity } from "types/SurveyDetailsTypes";
import { DepositTypes } from "types/DepositTypes";

const AuthContext = createContext<any>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserData>({} as UserData);
  const [flow, setFlow] = useState("sell");
  const [userDetails, setUserDetails] = useState<UserDetails>(
    {} as UserDetails
  );
  const navigate = useNavigate();
  const [referrals, setReferrals] = useState<RefereeId[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buyAmount, setBuyAmount] = useState<number | null>(null);
  const [buyAddress, setBuyAddress] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [buyCurrency, setBuyCurrency] = useState("");
  const [errorNotification, setErrorNotification] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [storedToken, setStoredToken] = useState<string | null>("");
  const [isWalletConfirmed, setIsWalletConfirmed] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<any>();
  const [bankSelected, setBankSelected] = useState(false);
  const [referralInfo, setReferralInfo] = useState();
  const [selectedAsset, setSelectedAsset] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [allRates, setAllRates] = useState<RatesEntity[]>([]);
  const [pinIsSet, setPinIsSet] = useState(false);
  const [payAmount, setPayAmount] = useState<number>(0);
  const [receiveAmount, setReceiveAmount] = useState<string>("");
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);

  const [allNotifications, setAllNotifications] = useState<
    NotificationsEntity[]
  >([]);
  const [survey, setSurvey] = useState<QuestionsEntity[]>([]);
  const [surveyLoading, setSurveyLoading] = useState<boolean>(true);
  const [surveyId, setSurveyId] = useState("");
  const [buyNetwork, setBuyNetwork] = useState("");
  const [solBuyRate, setSolBuyRate] = useState("");
  const [surveyIsSubmitted, setSurveyIsSubmitted] = useState(false);
  const [depositDetails, setDepositDetails] = useState<DepositTypes>(
    {} as DepositTypes
  );
  const [buyRate, setBuyRate] = useState<any>();

  const login = async (userData: UserData, email: string, password: string) => {
    const formData = {
      email: email,
      password: password,
    };

    try {
      setIsLoading(true);
      const response = await cwitoApi.post("/auth/signin", formData, {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        setUser((prevUser) => ({ ...prevUser, ...response.data }));
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          ...response.data,
        }));

        localStorage.setItem("accessToken", response.data.accessToken);
        setStoredToken(response.data.accessToken);
        navigate("/home");
      } else if (response.status === 401) {
        setIsLoading(false);
        setErrorNotification(true);
        setMessage("Something went wrong, please try again");
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.data.statusCode === 401) {
        setIsLoading(false);
        setErrorNotification(true);
        setMessage(error.response.data.message);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      } else {
        setIsLoading(false);
        setErrorNotification(true);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    }
  };
  // console.log("user details is", pinIsSet);
  // console.log("from context", localStorage.getItem("accessToken"))

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setStoredToken(token);
    }
  }, []);
  // console.log(userDetails);

  const fetchUserData = useCallback(async () => {
    try {
      setUserDetailsLoading(true);
      const response = await cwitoApi.get("/user", {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setUserDetailsLoading(false);

      if (response.status === 200) {
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          ...response.data.user,
        }));

        // console.log("response is", response.data);
        setPinIsSet(response?.data?.user?.pinSet);
      } else if (response.status === 401) {
        setUserDetailsLoading(false);

        setErrorNotification(true);
        setMessage("Something went wrong, please try again");
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    } catch (error) {
      setUserDetailsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (storedToken) {
      fetchUserData();
    }
  }, [storedToken, fetchUserData]);

  const setToken = () => {
    setStoredToken(localStorage.getItem("accessToken"));
  };

  const setWallet = () => {
    setIsWalletConfirmed(true);
  };

  const getAllNotifications = async () => {
    try {
      setIsLoading(true);
      const response = await cwitoApi.get("/user/notifications", {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setIsLoading(false);
      setNotificationCount(response.data.unreadCount);
      setAllNotifications(response.data.notifications);
    } catch (error) {
      setIsLoading(false);
      setMessage("Cannot retrieve notifications at this time.");
      setErrorNotification(true);
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    setUser({} as UserData);
    setIsLoading(false);
    setUserDetails({} as UserDetails);
    setStoredToken(null);
    setIsWalletConfirmed(false);
  };

  return (
    <AuthContext.Provider
      value={{
        buyAddress,
        setBuyAddress,
        buyCurrency,
        setBuyCurrency,
        buyAmount,
        setBuyAmount,
        flow,
        setFlow,
        userEmail,
        setUserEmail,
        pinIsSet,
        userDetailsLoading,
        setUserDetailsLoading,
        // flow,
        // setFlow,
        user,
        accessToken,
        setAccessToken,
        selectedAsset,
        setSelectedAsset,
        referralInfo,
        setReferralInfo,
        login,
        setToken,
        buyRate,
        setBuyRate,
        fetchUserData,
        logout,
        setWallet,
        getAllNotifications,
        notificationCount,
        allNotifications,
        isWalletConfirmed,
        storedToken,
        setSurvey,
        survey,
        setSurveyId,
        surveyId,
        setSurveyLoading,
        surveyLoading,
        userDetails,
        isLoading,
        errorNotification,
        message,
        bankSelected,
        setBankSelected,
        referrals,
        setReferrals,
        surveyIsSubmitted,
        setSurveyIsSubmitted,
        sellAmount,
        setSellAmount,
        allRates,
        setAllRates,
        depositDetails,
        setDepositDetails,
        buyNetwork,
        setBuyNetwork,
        payAmount,
        setPayAmount,
        receiveAmount,
        setReceiveAmount,
        solBuyRate,
        setSolBuyRate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
