import React, { useEffect, useState } from "react";
import { supportItems } from "../support/SupportItems";
import SupportButton from "../support/SupportButton";
import instagramIconDark from "../../assets/instagramDark.svg";
import twitterIcon from "../../assets/twitterIcon.svg";
import instagramIcon from "../../assets/instagram.svg";
import twitterIcondark from "../../assets/twitterIconDark.svg";
import infoIcon from "../../assets/infoSquare.svg";
import infoIconDark from "../../assets/infoSquaredark.svg";
import arrowRight from "../../assets/arrowRight.svg";
import arrowRightDark from "../../assets/arrowRightDark.svg";
import Faq from "./Faq";
import SurveyModal from "./SurveyModal";
import { useLocation } from "react-router-dom";
import { cwitoApi } from "configuration/AxiosConfig";
import { useAuth } from "configuration/AuthContext";
import SetPinModal from "./SetPinModal";
// import { QuestionsEntity, SurveyDetailsTypes } from "types/SurveyDetailsTypes";
// import SetPinModal from "./SetPinModal";

function Support() {
  const location = useLocation();

  const [view, setView] = useState<number>(1);
  const handleView = (view: number) => {
    setView(view);
  };
  const { pinIsSet, surveyIsSubmitted, userDetailsLoading, userDetails } =
    useAuth();

  return (
    <aside className="md:h-[100vh] dark:border-borderdark relative px-4  md:max-h-[100vh] md:border-l-[0.1px] border-gray-300 overflow-x-hidden pt-5 pb-20 md:py-5 overflow-y-auto md:pl-10 ">
      {view === 1 && (
        <div className="">
          <header className="flex justify-start w-full">
            <h1 className="font-bold text-[#1E194E] dark:text-white text-lg">
              Support
            </h1>
          </header>
          <div className="flex justify-between md:justify-start md:flex md:flex-col mt-2 ">
            {supportItems.map((item, index) => (
              <SupportButton
                key={index}
                to={item.to}
                iconDark={item.iconDark}
                icon={item.icon}
                title={item.title}
                subtitle={item.subtitle}
              />
            ))}
          </div>
          <button
            onClick={() => handleView(2)}
            className="flex justify-between mt-6 hover:bg-[#d0cfcf]/50 items-center w-full md:w-[309px] rounded-lg dark:bg-primary dark:text-[#EAFEFF]  bg-[#F8F8F8] p-4 "
          >
            <div className="flex justify-start gap-2 items-center">
              <img src={infoIcon} alt="asset icon" className="dark:hidden" />
              <img
                src={infoIconDark}
                alt="asset icon"
                className="dark:block hidden"
              />
              <div className="flex flex-col text-start">
                <p className="md:text-sm font-semibold text-[10px]">FAQs</p>
                <p className="text-xs">Frequently asked questions</p>
              </div>
            </div>
            <img src={arrowRight} alt="asset icon" className="dark:hidden" />
            <img
              src={arrowRightDark}
              alt="asset icon"
              className="dark:block hidden"
            />
          </button>
          <section className="flex flex-col gap-4  justify-start w-full mt-4">
            <h1 className="font-bold dark:text-buttonDark text-[#1E194E] text-sm md:text-lg text-start">
              Socials
            </h1>
            <div className="flex flex-col text-xs md:flex gap-2 md:flex-row w-full md:w-auto md:justify-start md:items-center">
              <a
                href="https://www.instagram.com/usecwito_?igsh=eHhsa2ZlNXp0amh3"
                target="blank"
                rel="norefferer"
                className="md:bg-white dark:bg-primary  gap-2 items-center flex md:block bg-[#F8F8F8] p-4 rounded-lg"
              >
                <img
                  src={instagramIcon}
                  alt="instagram Icon"
                  className="dark:hidden"
                />
                <img
                  src={instagramIconDark}
                  alt="instagram Icon"
                  className="dark:block hidden"
                />
                <p className="md:hidden">Instagram</p>
              </a>
              <a
                href="https://x.com/UseCwito"
                target="blank"
                rel="norefferer"
                className="md:bg-white dark:bg-primary  gap-3 items-center flex md:block bg-[#F8F8F8] p-4 rounded-lg"
              >
                <img
                  src={twitterIcon}
                  alt="twitter Icon"
                  className="dark:hidden"
                />
                <img
                  src={twitterIcondark}
                  alt="twitter Icon"
                  className="dark:block hidden"
                />
                <p className="md:hidden">X</p>
              </a>
            </div>
          </section>
          {!location.pathname.includes("survey") && !surveyIsSubmitted && (
            <div className="absolute hidden md:block bottom-16">
              <SurveyModal />
            </div>
          )}
          {!userDetailsLoading &&
            !location.pathname.includes("set-pin") &&
            !pinIsSet &&
            userDetails && (
              <div className="absolute hidden md:block bottom-16">
                <SetPinModal />
              </div>
            )}
        </div>
      )}
      {view === 2 && <Faq handleView={handleView} />}
    </aside>
  );
}
export default Support;
