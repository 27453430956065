import React from "react";
// import cancel from "../assets/cancel.svg";
interface NotificationProps {
  message: string;
  color: string;
  textColor: string;
  //   closeIcon: string;
  handleCloseNotification?: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  message,
  color,
  textColor,
  //   closeIcon,
  handleCloseNotification,
}) => {
  return (
    <div className="fixed flex min-w-fit justify-center items-start z-[100000] left-0 top-0 bottom-0 right-0">
      <div
        className={`${color} gap-10 flex  max-w-[80%] md:max-w-[31%] rounded text-start shadow-lg  mt-[3%] items-start justify-between px-6 py-3 font-nunito text-[10px] md:text-xs ${textColor} `}
      >
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Notification;
