import { useEffect, useState } from "react";
import lightIcon from "assets/lightIcon.svg";
import darkIcon from "assets/darkIcon.svg";

const ToggleButton = () => {
  const [isChecked, setIsChecked] = useState(() => {
    return (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    );
  });

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isChecked);
    localStorage.theme = isChecked ? "dark" : "light";
  }, [isChecked]);

  const toggleTheme = () => {
    setIsChecked((prev) => !prev);
  };

  return (
    <div className="flex items-center  justify-between item ">
      {/* <p
        className={`text-sm ${
          isChecked ? "dark:text-[#C8D0DB]" : "text-black"
        }`}
      >
        Dark mode
      </p> */}
      <label className="relative inline-block w-11 h-5">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={toggleTheme}
          className="absolute opacity-0 w-0 h-0"
        />
        <div
          className={`flex w-full h-full ${
            isChecked ? "bg-[#168B3F]" : "bg-gray-300"
          } items-center rounded-full cursor-pointer transition-colors duration-300 `}
        >
          <div
            className={`w-5 h-5 flex justify-center items-center rounded-full absolute transition-transform duration-300 ${
              isChecked ? "transform translate-x-[120%] bg-primary" : "bg-white"
            }`}
          >
            <img
              src={isChecked ? darkIcon : lightIcon}
              height={16}
              width={16}
              alt="Theme Icon"
            />
          </div>
        </div>
      </label>
    </div>
  );
};

export default ToggleButton;
