import React from "react";
import cancel from "../../assets/cancelIcon.svg";
import lockIcon from "../../assets/lockIcon.svg";
import arrow from "../../assets/greaterThanArrowGreen.svg";
import arrowRightWhite from "../../assets/arrowRightWhite.svg";
import { useNavigate } from "react-router-dom";

const SetPinModal = () => {
  const navigate = useNavigate();
  const goToSetPin = () => {
    navigate("/set-pin");
  };
  return (
    <div className="md:max-w-[70%] w-[90%] mx-auto  bg-[#E3F2F2] py-5 px-5 flex flex-col gap-2 rounded-2xl shadow-xl">
      <div className="w-full flex justify-end items-center">
        <button className="p-1">
          <img src={cancel} height={12} width={12} alt="close icon" />
        </button>
      </div>

      <div className="w-full gap-3 p items-center justify-between flex-col flex">
        <img src={lockIcon} alt="survey icon" />
        <button
          onClick={goToSetPin}
          className="text-[#168B3F] flex justify-center gap-2 items-center font-semibold "
        >
          Set Transaction Pin
          <span>
            <img src={arrow} alt="" />
          </span>
        </button>
        <p className="text-sm  text-start text-[#00000080]">
          Keep your transactions safe and quick. Just a few taps to secure your
          account!.
        </p>
      </div>
      {/* <div className="w-full flex justify-end">
        <button
          onClick={handleSurveyClick}
          className="bg-[#023436] py-1.5 px-3 rounded flex justify-center items-center"
        >
          <img src={arrowRightWhite} alt="arrowRightWhite" />
        </button>
      </div> */}
    </div>
  );
};

export default SetPinModal;
