import React, { useState } from "react";
import arrowBack from "../../assets/arrowBack.svg";
import arrow from "../../assets/arrowDropDown.svg";
import arrowBackDark from "assets/arrowBackDark.svg";

interface FaqProps {
  handleView: (view: number) => void;
}
const Accordion: React.FC<FaqProps> = ({ handleView }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqData = [
    {
      question: "How do I change my cwito password?",
      answer:
        "To change your cwito password, log into your cwito app, navigate to settings, click on Update Password, and fill in the required details",
    },
    {
      question: "How do I change my Cwito Username?",
      answer:
        "To change your cwito username, log into the cwito app, go to settings, click on Edit Profile, and input a username that suits you.",
    },
    {
      question: "What cryptocurrencies do you support?",
      answer: "We currently supports Tether (USDT) and BTC",
    },
    {
      question: "How do I check the daily rates for cryptocurrencies?",
      answer:
        "To check the daily rates for BTC and USDT, simply tap on the notification button. We update the rates every morning to keep you informed.",
    },
    {
      question: "What countries do we support for cashouts?",
      answer: "Currently, we support cashouts in Nigeria only.",
    },
    {
      question: "How do I set up my Two-factor authentication?",
      answer:
        "To set up Two-factor authentication, click on the KYC Notification popping up on your screen, enter your BVN, and a confirmation email will be sent to indicate that you've successfully completed the process.",
    },
  ];

  return (
    <div className="flex dark:text-white flex-col p-4 md:p-0 md:w-[65%] fixed md:relative top-0 left-0 right-0 bottom-0 h-screen md:h-auto ">
      <header className=" flex justify-start py-2 mb-3 gap-4 items-center w-full ">
        <button onClick={() => handleView(1)}>
          <img src={arrowBack} alt="arrow back" className="dark:hidden" />
          <img
            src={arrowBackDark}
            className="dark:block hidden"
            alt="arrow back"
          />
        </button>
        <p className="font-bold">FAQs</p>
      </header>
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="border-b dark:border-borderdark/20 border-[#F3F3F3] text-xs "
        >
          <div
            className="flex justify-between items-center p-4 cursor-pointer dark:bg-bgDarkSecondary bg-white"
            onClick={() => toggleAccordion(index)}
          >
            <div className="font-medium transition-all duration-300 ease-in-out  text-start">
              {faq.question}
            </div>
            <img
              src={arrow}
              alt=""
              className={
                activeIndex === index
                  ? "rotate-180 transition-all duration-300 ease-in-out"
                  : "transition-all duration-300 ease-in-out"
              }
            />
          </div>
          {activeIndex === index && (
            <div className="px-4 pb-3 transition-all duration-300 ease-in-out">
              <p className=" text-start">{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
