// VerifyEmail.tsx
import React, { useState } from "react";
import back from "../../assets/back.svg";
import OnboardingLeftSide from "../../components/onboarding/OnboardingLeftSide";
import CodeInput from "components/onboarding/CodeInput";
import Notification from "components/Notification";
import { useNavigate, useLocation } from "react-router-dom";
import { cwitoApi } from "configuration/AxiosConfig";
import { ReactComponent as LoadingIcon } from "../../assets/LoadingGray.svg";
import { useAuth } from "configuration/AuthContext";

const ConfirmTwoFA: React.FC = () => {
  const { storedToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userEmail = location.state?.email || "";
  const [message, setMessage] = useState<string>("");
  const [successNotification, setSuccessNotification] =
    useState<boolean>(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);
  const closeNotification = () => {
    setSuccessNotification(false);
    setErrorNotification(false);
  };

  const goBack = () => {
    navigate("/settings/two-factor-auth");
  };

  const handleConfirm2FA = async (code: string) => {
    const formData = {
      token: code,
    };

    try {
      setIsLoading(true);
      await cwitoApi
        .post("/user/verify-email-2fa", formData, {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            // console.log("response is", response);
            setSuccessNotification(true);
            setMessage(response.data.message);
            setTimeout(() => {
              setErrorNotification(false);
              navigate("/settings");
            }, 3000);
          }
        });

      // console.log(response);
    } catch (response: any) {
      setIsLoading(false);
      if (response.response.data.statusCode === 400) {
        setErrorNotification(true);
        setMessage(response.response.data.message);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      } else if (response.response.data.statusCode === 404) {
        setErrorNotification(true);
        setMessage(response.response.data.message);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      } else {
        setErrorNotification(true);
        setMessage(response.response.data.message);
        setTimeout(() => {
          setErrorNotification(false);
        }, 3000);
      }
    }
  };

  return (
    <div className="w-full h-screen md:py-10 overflow-y-auto ">
      {successNotification && (
        <Notification
          message={message}
          color={"bg-[#50c878]"}
          textColor={"text-white"}
        />
      )}
      {errorNotification && (
        <Notification
          message={message}
          color={"bg-[#C85050]"}
          textColor={"text-white"}
        />
      )}
      <section className="w-full">
        <div className="flex flex-col  justify-start items-start px-5 py-11  w-full h-full">
          <div className="w-full   h-full  gap-6  flex flex-col justify-center items-center">
            <header className="w-full flex gap-2  md:justify-center items-center">
              <button onClick={() => goBack()}>
                <img src={back} alt="back button" />
              </button>
              <h3 className="font-bold  dark:text-white text-base sm:text-2xl">
                Confirm 2FA
              </h3>
            </header>
            <div className=" w-full dark:text-white text-center text-xs">
              An OTP has been sent to you email, kindly input the OTP to
              continue.
            </div>
            {isLoading ? (
              <div className="flex justify-center  z-50">
                <LoadingIcon className="suspense-loading-icon" />
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <CodeInput onCodeChange={(code) => handleConfirm2FA(code)} />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ConfirmTwoFA;
