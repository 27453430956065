import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";

import Support from "./support/Support";
import SurveyModal from "./support/SurveyModal";
import { useAuth } from "configuration/AuthContext";
import SetPinModal from "./support/SetPinModal";

type Props = {
  children: ReactNode;
};

const GrandLayout: React.FC<Props> = ({ children }) => {
  const { surveyIsSubmitted, pinIsSet, userDetails, userDetailsLoading } =
    useAuth();

  const location = useLocation();
  const shouldRenderSidebar =
    location.pathname !== "/" &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/personal-details" &&
    location.pathname !== "/create-pin" &&
    location.pathname !== "/confirm-pin" &&
    location.pathname !== "/login" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/verify-code" &&
    location.pathname !== "/new-user-survey" &&
    location.pathname !== "/set-new-password" &&
    location.pathname !== "*" &&
    location.pathname !== "notifications";
  // location.pathname !== "/create-new-password" &&
  // location.pathname !== "/password-reset-successful";
  const shouldRenderSupport =
    location.pathname !== "/" &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/personal-details" &&
    location.pathname !== "/create-pin" &&
    location.pathname !== "/confirm-pin" &&
    location.pathname !== "/login" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/verify-code" &&
    location.pathname !== "/new-user-survey" &&
    location.pathname !== "/set-new-password" &&
    location.pathname !== "*" &&
    location.pathname !== "notifications";

  const sectionClassName =
    location.pathname === "/" ? "md:h-[100vh]" : "md:h-[100vh] ";

  return (
    <main className="w-full flex dark:bg-[#001718] flex-col md:flex md:flex-row md:h-screen justify-start md:justify-between overflow-hidden">
      {shouldRenderSidebar && (
        <div className="w-[30%] md:pt-10">
          <aside>
            <Sidebar />
          </aside>
        </div>
      )}
      <div className="w-full dark:bg-[#001718] md:w-[40%]">
        <section className={sectionClassName}>
          {children}

          {!location.pathname.includes("survey") &&
            !surveyIsSubmitted &&
            shouldRenderSidebar && (
              <div className="fixed bottom-20 md:bottom-16 right-0 w-full md:hidden">
                <SurveyModal />
              </div>
            )}
          {!userDetailsLoading &&
            userDetails &&
            !location.pathname.includes("set-pin") &&
            !pinIsSet &&
            shouldRenderSidebar && (
              <div className="absolute block md:hidden bottom-16">
                <SetPinModal />
              </div>
            )}
        </section>
      </div>

      {shouldRenderSupport && (
        <div className="w-[30%] dark:bg-[#001718] hidden md:block md:pt-10">
          <aside>
            <Support />
          </aside>
        </div>
      )}
    </main>
  );
};

export default GrandLayout;
