


import { useEffect } from "react";
import Routers from "./Routers";
import "./App.css";
import NotificationPermission from "components/push/NotificationPermission";
import RegisterPush from "pushNotification/RegisterPush";
import { useAuth } from "configuration/AuthContext";
// import NotifyButton from "components/push/NotifyButton";

if (
  localStorage.theme === "dark" ||
  (!("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  document.documentElement.classList.add("dark");
} else {
  document.documentElement.classList.remove("dark");
}

function App() {
  const { storedToken } = useAuth();
  useEffect(() => {
    const registerPush = async () => {
      try {
        await RegisterPush(storedToken);
        console.log("RegisterPush executed successfully");
      } catch (error) {
        console.error("Error executing RegisterPush:", error);
      }
    };

    registerPush();
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    document.title = "Cwito";
    return function cleanup() {
      ac.abort();
    };
  }, []);

  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.type === "NEW_VERSION_AVAILABLE") {
      // You can notify the user and reload the page
      alert("A new version is available. Please refresh the page.");
    }
  });

  return (
    <div className="App app-container dark:bg-bgDark">
      <NotificationPermission />
      {/* <NotifyButton /> */}
      <Routers />
    </div>
  );
}

export default App;
